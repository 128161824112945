export default function BookTitle() {
  return (
    <div className="mx-auto w-1/3 items-center py-2">
      <h2 className="my-2 text-center font-mono text-2xl italic text-gray-600">BEYOND</h2>
      <h2 className="my-2 text-center font-mono text-2xl">CRACKING</h2>
      <div className="relative">
        <div aria-hidden="true" className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-2 text-sm italic text-gray-500">the</span>
        </div>
      </div>
      <h2 className="my-2 text-center font-mono text-2xl">CODING INTERVIEW</h2>
    </div>
  );
}

import React from 'react';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { useBeyondCTCIBookSection } from '../../../hooks/BeyondCTCIBook/useBeyondCTCIBookSection';
import { useCanViewBeyondCTCIBook } from '../../../hooks/BeyondCTCIBook/useCanViewBeyondCTCIBook';
import { TBeyondCTCIBookSection } from '../../../types/BeyondCTCIBookSection';

type BeyondCTCIBookSectionPageProps = {
  sectionSlug: string;
};

type BeyondCTCIBookSectionContentProps = {
  bookSection: TBeyondCTCIBookSection;
};

const BeyondCTCIBookSectionContent = ({ bookSection }: BeyondCTCIBookSectionContentProps) => (
  <React.Fragment>
    <h3 className="text-xl">{bookSection.attributes.Title}</h3>
    {bookSection.attributes.Body && <BlocksRenderer content={bookSection.attributes.Body} />}
  </React.Fragment>
);

const BeyondCTCIBookSectionPage = ({ sectionSlug }: BeyondCTCIBookSectionPageProps) => {
  const isUserAllowed = useCanViewBeyondCTCIBook();
  const { data: bookSection, isLoading, isFetched } = useBeyondCTCIBookSection(sectionSlug);

  if (!isUserAllowed) return null;

  return (
    <div className="border border-black p-4">
      <a href={`${window?.CONFIG.beyondCTCIBaseUrl}`}>Back to table of contents</a>
      <h2 className="my-2 text-2xl underline">Beyond Cracking the Coding Interview</h2>
      {isLoading && <p>Loading...</p>}
      {isFetched && <BeyondCTCIBookSectionContent bookSection={bookSection} />}
    </div>
  );
};

export default BeyondCTCIBookSectionPage;

import { PropsWithChildren } from 'react';

interface CardProps {
  title: string;
  footer?: JSX.Element;
}

export default function Card({ children, title, footer }: PropsWithChildren<CardProps>) {
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-50 shadow-xl">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-2xl">{title}</h2>
      </div>
      <div className="px-4 py-5 sm:p-6">{children}</div>
      {footer}
    </div>
  );
}

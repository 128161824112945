import { useQuery } from '@tanstack/react-query';

import axios from '../../utils/axios';
import { TBeyondCTCIBookSection } from '../../types/BeyondCTCIBookSection';

export const useBeyondCTCIBookSection = (sectionSlug: string) =>
  useQuery({
    queryKey: [`beyondCTCIBookSection-${sectionSlug}`],
    queryFn: (): Promise<TBeyondCTCIBookSection> =>
      axios.get(`api/strapi/book/sections/${sectionSlug}`).then((response) => response.data?.data?.[0]),
    refetchInterval: 60 * 1000,
  });

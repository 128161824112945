import { useEffect, useState, ComponentType } from 'react';
import { TSale } from '../../../types/ProductCatalog';

export type DedicatedCoachingBannerProps = {
  activeSale: TSale;
  showDescription?: boolean;
  customIcon?: ComponentType;
};

export const DedicatedCoachingSaleBanner = (props: DedicatedCoachingBannerProps) => {
  const { activeSale, customIcon: CustomIcon, showDescription = true } = props;
  const [IconComponent, setIconComponent] = useState<ComponentType<React.SVGProps<SVGSVGElement>> | null>(null);

  useEffect(() => {
    async function loadIcon() {
      if (activeSale.iconPath) {
        try {
          const { default: Icon } = await import(`@heroicons/${activeSale.iconPath}`);
          setIconComponent(() => Icon);
        } catch (error) {
          window.Rollbar.error(`Failed to load icon from path: @heroicons/${activeSale.iconPath}`, error);
        }
      }
    }
    if (CustomIcon) {
      setIconComponent(CustomIcon);
    } else {
      loadIcon();
    }
  }, [activeSale.iconPath]);

  return (
    <div className="flex grow flex-col rounded bg-yellow p-2 sm:flex-row sm:gap-8 sm:p-3 sm:align-middle">
      <div className="flex shrink flex-col justify-center">
        {IconComponent && (
          <IconComponent
            className="sm:h-18 sm:w-18 h-12 w-full shrink object-scale-down object-center"
            aria-hidden="true"
          />
        )}
      </div>
      <div className="flex flex-col justify-center text-center align-middle text-lg font-bold leading-normal tracking-widest lg:text-2xl">
        <span>{activeSale.bannerHeadline}</span>
      </div>
      {showDescription && (
        <div className="text-md flex flex-col justify-center text-center font-sans sm:text-left lg:text-lg">
          <div dangerouslySetInnerHTML={{ __html: activeSale.bannerDescription }} />
        </div>
      )}
    </div>
  );
};

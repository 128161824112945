import { useQuery } from '@tanstack/react-query';

import { TBeyondCTCIBookPart } from '../../types/BeyondCTCIBookPart';
import axios from '../../utils/axios';

export const useBeyondCTCIBookPart = (partSlug: string) =>
  useQuery({
    queryKey: [`beyondCTCIBookPart-${partSlug}`],
    queryFn: (): Promise<TBeyondCTCIBookPart> =>
      axios.get(`api/strapi/book/parts/${partSlug}`).then((response) => response.data?.data?.[0]),
    refetchInterval: 60 * 1000,
  });

import { TBeyondCTCIBookChapter } from '../../types/BeyondCTCIBookChapter';
import axios from '../../utils/axios';
import { useQuery } from '@tanstack/react-query';

export const useBeyondCTCIBookChapter = (chapterSlug: string) =>
  useQuery({
    queryKey: [`beyondCTCIBookChapter-${chapterSlug}`],
    queryFn: (): Promise<TBeyondCTCIBookChapter> =>
      axios.get(`api/strapi/book/chapters/${chapterSlug}`).then((response) => response.data?.data?.[0]),
    refetchInterval: 60 * 1000,
  });

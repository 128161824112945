import { TBeyondCTCIBookPart } from '../../types/BeyondCTCIBookPart';
import axios from '../../utils/axios';
import { useQuery } from '@tanstack/react-query';

export const useBeyondCTCIBookTableOfContents = () =>
  useQuery({
    queryKey: ['beyondCTCIBookTableOfContents'],
    queryFn: (): Promise<TBeyondCTCIBookPart[]> =>
      axios.get('api/strapi/book/table-of-contents').then((response) => response.data?.data),
    refetchInterval: 60 * 1000,
  });

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';

window.loadReact = () => {
  const container = document.getElementById('react_app');
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

export {};

import React from 'react';
import { useCanViewBeyondCTCIBook } from '../../../hooks/BeyondCTCIBook/useCanViewBeyondCTCIBook';
import BeyondCTCIBookTableOfContents from '../BeyondCTCIBookTableOfContentsPage/BeyondCTCIBookTableOfContentsPage';
import BookTitle from '../../../components/BeyondCTCI/BookTitle';

const BeyondCTCIBookLandingPage = () => {
  const isUserAllowed = useCanViewBeyondCTCIBook();

  if (!isUserAllowed) return null;

  return (
    <div className="flex max-w-screen-lg justify-center gap-4">
      <div className="flex-1">
        <BookTitle />

        <BeyondCTCIBookTableOfContents />
      </div>
    </div>
  );
};

export default BeyondCTCIBookLandingPage;

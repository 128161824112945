import { useEffect, useState } from 'react';
import { useUser } from '../../userContext';

export const useCanViewBeyondCTCIBook = () => {
  const { user } = useUser();
  const [isUserAllowed, setIsUserAllowed] = useState<boolean>(false);

  useEffect(() => {
    for (const { variant, experimentName } of user.forcedExperimentVariants) {
      if (experimentName === 'viewBookExperiment' && variant === 1) {
        setIsUserAllowed(true);
        return;
      }
    }
    document.location.href = '/'; // Redirect to home page if user is not allowed to view book
  }, [user]);

  return isUserAllowed;
};

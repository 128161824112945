import { PropsWithChildren } from 'react';

interface LinkAsButtonProps {
  href: string;
}

export default function LinkAsButton({ children, href }: PropsWithChildren<LinkAsButtonProps>) {
  return (
    <a
      className="rounded-md bg-white px-2.5 py-1.5 text-center text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      href={href}
    >
      {children}
    </a>
  );
}
